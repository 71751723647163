import React from 'react';

const Mission = () => {
    return (
        <div className="flex flex-col justify-center items-center min-h-[90vh] w-full lg:mt-10 px-4">
            <h1 className='text-4xl text-center tracking-tight font-bold text-slate-800 max-w-7xl sm:text-5xl md:text-6xl lg:text-8xl'>
                Our Mission
            </h1>
            <p className='text-center max-w-4xl mt-4 text-slate-500'>
                At Masxon, our mission is to bridge the gap between Artificial Intelligence and Blockchain technology,
                creating a gamified ecosystem that empowers users to engage, create, and innovate in a decentralized world.
                We strive to provide the tools and platforms necessary for individuals and organizations to seamlessly
                integrate these cutting-edge technologies into their everyday lives, fostering a community of innovation and collaboration.
            </p>

            <h2 className='text-3xl text-center font-bold text-slate-800 mt-10'>
                Roadmap
            </h2>

            <div className='max-w-4xl mt-4 text-slate-500'>
                <h3 className='text-xl font-semibold mt-6'>Coming Soon: Discord Community Badge Minter</h3>
                <p>
                    Launch a tool that allows community members to mint badges directly on Discord. These badges will be used to recognize roles such as volunteers, mentors, and admins within our or your ecosystem.
                </p>
                <p>
                    - First NFT drop
                </p>

                <h3 className='text-xl font-semibold mt-6'>Q4 2024: Viewing and Trading of NFTs</h3>
                <p>
                    Implement features that enable users to view and trade their NFTs within our platform, fostering a more interactive and dynamic marketplace.
                </p>

                <h3 className='text-xl font-semibold mt-6'>Q1 2025: Release of RBBithole</h3>
                <p>
                    Launch RBBithole, an AI-powered UI that generates and elaborates on random facts, allowing users to mint these facts as NFTs. Users can receive rewards for utilizing these NFTs in creative ways, such as incorporating them into stories or educational materials.
                </p>

                <h3 className='text-xl font-semibold mt-6'>Q2 2025: Flux Power NFT Minter</h3>
                <p>
                    Introduce an innovative NFT minter that generates AI-powered images in real-time, allowing users to mint these unique creations as NFTs. This feature will include a dynamic element, where the minted artwork can evolve or change based on user interactions.
                </p>

                <h3 className='text-xl font-semibold mt-6'>Q3 2025: DigiCards</h3>
                <p>
                    Launch DigiCards, a 2D card game where players purchase blank cards and customize them with their own artwork. These custom cards can be minted as NFTs, used in battles, and traded with other players. DigiCards will foster a creative and competitive community.
                </p>

                <h3 className='text-xl font-semibold mt-6'>Q4 2025: Release of Emphemeral Echoes on Steam</h3>
                <p>
                    Emphemeral Echoes is an indie game that will be released on Steam. While not a blockchain-based game, 50% of the profits will be allocated to a shared wallet, with revenue split among NFT holders and MXN token holders. This approach will demonstrate our commitment to sharing revenue across both crypto and non-crypto projects, solving the problem of DAOs not producing revenue.
                </p>

                <h3 className='text-xl font-semibold mt-6'>Q4 2026: Skizm App</h3>
                <p>
                    Develop and release the Skizm app, an AR application that allows users to create and display 2D and 3D NFTs in real-world environments. Users will be able to complete quests in real life to earn and generate certain NFTs. Additionally, users can earn MXN within the Skizm ecosystem, further integrating our token into everyday activities.
                </p>
            </div>

            <h2 className='text-3xl text-center font-bold text-slate-800 mt-10'>
                Revenue Sharing Model
            </h2>

            <div className='max-w-4xl mt-4 text-slate-500'>
                <p>
                    At Masxon, we believe in the power of shared success. Our revenue-sharing model ensures that profits from both our crypto-related and traditional projects are distributed among our community members. Whether through NFT holdings, MXN token ownership, or participation in our ecosystem, you have the opportunity to earn a share of the profits.
                </p>

                <h3 className='text-xl font-semibold mt-6'>Tiered Revenue Sharing:</h3>
                <ul className='list-disc pl-6'>
                    <li>
                        <strong>Tier 1:</strong> Holders of rare NFTs and large MXN token amounts receive 30% of profits.
                    </li>
                    <li>
                        <strong>Tier 2:</strong> Regular NFT holders and moderate MXN token holders receive 20% of profits.
                    </li>
                    <li>
                        <strong>Tier 3:</strong> All community members holding any NFT or MXN tokens receive 10% of profits.
                    </li>
                </ul>
            </div>

            <h2 className='text-3xl text-center font-bold text-slate-800 mt-10'>
                Tokenomics
            </h2>

            <div className='max-w-4xl mt-4 text-slate-500'>
                <h3 className='text-xl font-semibold mt-6'>Token Name: Masxon (MXN)</h3>
                <p>
                    The Masxon token (MXN) is designed to power our ecosystem, incentivize early adopters, and fuel our journey toward listing on a decentralized exchange (DEX). The total supply of MXN is set at 10 billion tokens, all of which are available for the community.
                </p>

                <h3 className='text-xl font-semibold mt-6'>Distribution</h3>
                <p>
                    Unlike traditional tokenomics models, there are no allocations for teams, marketing, or development. Every MXN token is reserved for community distribution, specifically to reward early adopters who participate in the minting process.
                </p>
                <p>
                    <strong>Early Adopter Incentive:</strong> Until we reach our $15,000 USD goal and list on a DEX, every Klay you spend minting NFTs with our tool will be matched with an airdrop of MXN tokens. This approach ensures that the community directly benefits from supporting the project early on.
                </p>

                <h3 className='text-xl font-semibold mt-6'>Utility of MXN</h3>
                <ul className='list-disc pl-6'>
                    <li>Minting NFTs: Use MXN to mint exclusive NFTs within the Masxon ecosystem.</li>
                    <li>In-Platform Rewards: Earn MXN by participating in platform activities and challenges.</li>
                    <li>Governance: Holders of MXN will have the opportunity to participate in future governance decisions as the platform evolves.</li>
                    <li>Liquidity: Once listed on a DEX, MXN will be tradeable, offering liquidity to holders.</li>
                </ul>

                <h3 className='text-xl font-semibold mt-6'>Contract Address</h3>
                <p>
                    The official contract address for the Masxon (MXN) token is:
                    <a href="https://klaytnscope.com/account/0x34ce525F90b860A3B0cBb7A85887Dd57C77188BB?tabId=internalTx" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"> 0x34ce525F90b860A3B0cBb7A85887Dd57C77188BB</a>.
                    We encourage all users to verify the contract address before engaging with the token.
                </p>
            </div>
        </div>
    );
}

export default Mission;
