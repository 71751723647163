import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        // Listen for the beforeinstallprompt event
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Save the event for later use
            setDeferredPrompt(e);
        });
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-[90vh] w-full lg:-mt-10">
            <h1 className='text-4xl text-center tracking-tight font-bold text-slate-800 max-w-7xl sm:text-5xl md:text-6xl lg:text-8xl'>
                Masxon
            </h1>
            <p className='text-center max-w-4xl mt-4 text-slate-500'>
                The Gamification of A.I. and Blockchain
            </p>

            {/* CTA buttons */}
            <div className='flex flex-col sm:flex-row gap-4 mt-8 px-4 w-full justify-center'>
                <Link
                    to='/mint'
                    className='inline-flex items-center justify-center w-full sm:w-fit px-10 py-3 border border-transparent text-base font-medium rounded-md text-white bg-slate-800 hover:bg-slate-900'
                >
                    Mint
                </Link>
                <Link
                    to="/mission"
                    className='inline-flex items-center justify-center w-full sm:w-fit px-10 py-3 border border-transparent text-base font-medium rounded-md text-slate-700 bg-slate-200 hover:bg-slate-300'
                >
                    Mission
                </Link>   
            </div>

            {/* Spacing between buttons and special offer */}
            <div className='mt-8 w-full flex justify-center px-4'>
                <div className="bg-blue-100 text-center p-4 rounded-md mb-4 max-w-4xl">
                    <h2 className='text-lg font-medium text-blue-700'>
                        Special Offer: Get MXN Airdrop for Every Klay Spent!
                    </h2>
                    <p className='text-blue-600 mt-2'>
                        Until we hit our $15,000 USD goal and list on a DEX, every Klay you spend minting NFTs with our tool will be matched with an airdrop of MXN tokens. Don’t miss out on this limited-time offer!
                    </p>   
                </div>
            </div>

            {/* Install App button */}
            <div className='mt-8 w-full flex justify-center px-4'>
                <button
                    onClick={handleInstallClick}
                    className='inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700'
                >
                    Install Masxon App
                </button>
            </div>
        </div>
    );
}

export default Home;
