import React from 'react';

const Docs = () => {
    return (
        <div className="flex flex-col justify-center items-center min-h-[90vh] w-full lg:mt-10 px-4">
            <h1 className='text-4xl text-center tracking-tight font-bold text-slate-800 max-w-7xl sm:text-5xl md:text-6xl lg:text-8xl'>
                Masxon Documentation
            </h1>

            <div className="mt-8 w-full max-w-4xl bg-white rounded-lg shadow-md p-6">
                <h2 className="text-2xl font-bold text-slate-800 mb-4">Overview</h2>
                <p className="text-slate-500 mb-4">
                    Welcome to the Masxon ecosystem documentation. Here you’ll find information on our mission, how to get started with our tools, and detailed instructions on using various features within our ecosystem.
                </p>

                <h2 className="text-2xl font-bold text-slate-800 mb-4">Getting Started</h2>
                <p className="text-slate-500 mb-4">
                    Masxon aims to bridge the gap between Artificial Intelligence and Blockchain technology. Our tools and platforms are designed to empower users to engage, create, and innovate in a decentralized world.
                </p>
                <p className="text-slate-500 mb-4">
                    The first step to joining our ecosystem is setting up a wallet that supports Klaytn, such as MetaMask. You can download MetaMask from the <a href="https://metamask.io/" className="text-blue-600 hover:underline">official website</a> and follow the setup instructions.
                </p>

                <h3 className="text-xl font-semibold text-slate-800 mb-2">Connecting to Klaytn</h3>
                <p className="text-slate-500 mb-4">
                    After setting up MetaMask, you’ll need to connect to the Klaytn network. To do this, open MetaMask, click on the network dropdown at the top, select “Custom RPC,” and enter the following details:
                </p>
                <ul className="list-disc pl-6 text-slate-500 mb-4">
                    <li><strong>Network Name:</strong> Klaytn Cypress</li>
                    <li><strong>New RPC URL:</strong> https://public-en-cypress.klaytn.net</li>
                    <li><strong>Chain ID:</strong> 8217</li>
                    <li><strong>Currency Symbol:</strong> KLAY</li>
                    <li><strong>Block Explorer URL:</strong> https://scope.klaytn.com</li>
                </ul>

                <h3 className="text-xl font-semibold text-slate-800 mb-2">Using the NFT Minter</h3>
                <p className="text-slate-500 mb-4">
                    Our NFT Minter tool allows you to easily create NFTs on the Klaytn network. Once you’ve connected your wallet, navigate to the minting page, upload your image, input the necessary metadata (name and description), and click “Mint.” 
                    You’ll be prompted to confirm the transaction in MetaMask. Once confirmed, your NFT will be minted and viewable on platforms like OpenSea.
                </p>
                <p className="text-slate-500 mb-4">
                    For a limited time, every Klay you spend on minting will be matched with an airdrop of MXN tokens until we reach our $15,000 USD goal and list on a DEX.
                </p>

                <h3 className="text-xl font-semibold text-slate-800 mb-2">RBBithole</h3>
                <p className="text-slate-500 mb-4">
                    RBBithole is our upcoming AI-powered tool that allows users to generate and elaborate on random facts, which can then be minted as NFTs. This tool is designed to integrate creativity with blockchain technology, providing users with unique ways to interact with and monetize their content.
                </p>
                <p className="text-slate-500 mb-4">
                    Detailed documentation on how to use RBBithole will be provided once the API and SDK are available. Stay tuned for updates.
                </p>

                <h3 className="text-xl font-semibold text-slate-800 mb-2">Revenue Sharing Model</h3>
                <p className="text-slate-500 mb-4">
                    Masxon’s revenue-sharing model ensures that profits from both crypto-related and traditional projects are distributed among our community members. Whether through NFT holdings, MXN token ownership, or active participation in our ecosystem, you have the opportunity to earn a share of the profits.
                </p>
                <ul className="list-disc pl-6 text-slate-500 mb-4">
                    <li><strong>Tier 1:</strong> Holders of rare NFTs and large MXN token amounts receive 30% of profits.</li>
                    <li><strong>Tier 2:</strong> Regular NFT holders and moderate MXN token holders receive 20% of profits.</li>
                    <li><strong>Tier 3:</strong> All community members holding any NFT or MXN tokens receive 10% of profits.</li>
                </ul>

                <h3 className="text-xl font-semibold text-slate-800 mb-2">Future Developments</h3>
                <p className="text-slate-500 mb-4">
                    As we continue to develop our ecosystem, we will provide further documentation on new tools and features, including the Flux Power NFT Minter, DigiCards, and the Skizm app. These tools are designed to expand the capabilities of the Masxon platform, integrating more aspects of AI and blockchain into daily user activities.
                </p>

                <h2 className="text-2xl font-bold text-slate-800 mb-4">FAQs</h2>
                <h3 className="text-xl font-semibold text-slate-800 mb-2">What is Masxon?</h3>
                <p className="text-slate-500 mb-4">
                    Masxon is an innovative platform that bridges Artificial Intelligence and Blockchain technology, creating a gamified ecosystem that empowers users to engage, create, and innovate in a decentralized world.
                </p>

                <h3 className="text-xl font-semibold text-slate-800 mb-2">How do I mint an NFT?</h3>
                <p className="text-slate-500 mb-4">
                    You can mint an NFT by connecting your wallet to the Masxon platform, navigating to the NFT Minter, uploading an image, adding metadata, and confirming the transaction in MetaMask.
                </p>

                <h3 className="text-xl font-semibold text-slate-800 mb-2">What is RBBithole?</h3>
                <p className="text-slate-500 mb-4">
                    RBBithole is an AI-powered tool that generates and elaborates on random facts, allowing users to mint these as NFTs. More details will be available when the tool is officially released.
                </p>
            </div>
        </div>
    );
}

export default Docs;
